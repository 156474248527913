@import "../entrypoint_init";

$theme-apple-primary: hsl(23deg 17% 42%);
$theme-apple-primary-filter: brightness(0%) saturate(100%) invert(42%) sepia(37%)
  saturate(260%) hue-rotate(340deg) brightness(89%) contrast(88%);
$theme-apple-secondary: hsl(354deg 71% 67%);
$theme-apple-secondary-filter: brightness(0%) saturate(100%) invert(56%) sepia(76%)
  saturate(956%) hue-rotate(310deg) brightness(96%) contrast(88%);

.theme {
  &.theme-apple {
    @include theme-variables(
      $theme-apple-primary,
      $theme-apple-secondary,
      $theme-apple-primary-filter,
      $theme-apple-secondary-filter
    );
    @include theme($theme-apple-primary, $theme-apple-secondary);
  }
}

.memorial-theme-option {
  &[data-theme-name="theme-apple"] {
    @include theme-preview($theme-apple-primary, $theme-apple-secondary);
  }
}
